import React from 'react'
import ReactAudioPlayer from 'react-audio-player';
import rs from '../rs.mp3'

export default function Audio() {
    return (
        <div className="flex items-stretch bg-gray-200 h-24">
            <div className="flex-1 text-gray-700 text-center bg-gray-400 px-4 py-2 m-2"></div>
            <div className="self-auto flex-1 text-gray-800 text-center bg-gray-500 px-4 py-2 m-2">
                <p>Red Sparrow</p>
                <ReactAudioPlayer
                    src={rs}
                    onPlay
                    controls
                />
            </div>
            <div className="flex-1 text-gray-700 text-center bg-gray-400 px-4 py-2 m-2"></div>

        </div >



    )
}
