import React from 'react';
import './tailwind.output.css';
import ImagePage from './ImagePage';
// import REACT_APP_PIXABAY_API_KEY from './.env'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Nav from './components/Nav';
import Audio from './components/Audio';
import Video from './components/Video';


function App() {


  return (
    <>
      <Router>
        <Nav />
        <Switch>
          <Route exact={true} path="/" component={ImagePage} />
          <Route path="/audio" component={Audio} />
          <Route path="/video" component={Video} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
