import React, { useState, useEffect } from 'react';
import ImageCard from './components/ImageCard';
import ImageSearch from './components/ImageSearch'
// import REACT_APP_PIXABAY_API_KEY from './.env'


function ImagePage() {
    const [images, setImages] = useState([])
    const [isLoadig, setIsloading] = useState(true)
    const [term, setTerm] = useState('')
    const REACT_APP_PIXABAY_API_KEY = "8761127-15c354fd40a23de8d36bfe25d"
    useEffect(() => {
        fetch(`https://pixabay.com/api/?key=${REACT_APP_PIXABAY_API_KEY}&q=${term}&image_type=photo&pretty=True`)
            .then(res => res.json())
            .then(data => {
                setImages(data.hits)
                setIsloading(false)
            })
            .catch(err => console.log(err))
    }, [term])

    return (
        <div className="container mx-auto">
            <ImageSearch searchText={(text) => setTerm(text)} />

            {!isLoadig && images.length === 0 && <h1 className="text-6xl text-center mx-auto mt-32">No Photos Found!</h1>}

            {isLoadig ? <h1 className="text-6xl text-center mx-auto mt-32">Loading...</h1> :
                <div className="grid grid-cols-3 gap-4">
                    {images.map(image => (
                        <ImageCard key={image.id} image={image} />
                    ))}
                </div>}
        </div>

    );
}

export default ImagePage;
