import React from 'react'
import reh from '../reh.mp4'
import { Player } from 'video-react';
import "../../node_modules/video-react/dist/video-react.css";


export default function Video() {
    return (
        <div className="box-border h-300 w-500 p-4 border-4 border-gray-400 bg-gray-200">
            <p className="center">English Lesson</p>
            <Player
                playsInline
                src={reh}
            // src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
            />
        </div>
    )
}
